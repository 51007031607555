import { defineNuxtRouteMiddleware } from 'nuxt/app';
import type { RouteLocationNormalized } from 'vue-router';

import { MEMBER_PAGE_NAME } from '@/constants/url.const';
import { useMemberStore } from '@/stores/member.store';
import { redirectTo } from '@/utils/common.util';

export default defineNuxtRouteMiddleware(async (_to: RouteLocationNormalized) => {
  const memberStore = useMemberStore();
  const hasWaitList = await memberStore.fetchWaitList(1);
  if (hasWaitList) {
    return;
  }
  return await redirectTo(MEMBER_PAGE_NAME, { toType: 'name' });
});
